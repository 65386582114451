
import en from './en.json';
import ru from './ru.json';
import de from './de.json';
import fr from './fr.json';
import es from './es.json';
import lt from './lt.json';
import ar from './ar.json';
import pl from './pl.json';
import it from './it.json';
import cz from './cz.json';
import ja from './ja.json';
import pt from './pt.json';
import tr from './tr.json';
import ro from './ro.json';

export const languages = { en, ru, de, fr, es, lt, ar, pl, it, cz, ja, pt, tr, ro }